import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InnerPageHeading from './InnerPageHeading';
import CountryForm from './CountryForm';

const CountryDetails = () => {
  const { id } = useParams();  // use 'id' instead of 'country'
  const [content, setContent] = useState(<div>Page Coming Soon...</div>);
  const [backgroundImage, setBackgroundImage] = useState("/images/page_header.jpg");

  useEffect(() => {
    if (!id) {
      setContent(<div>Page Coming Soon...</div>);
      setBackgroundImage("/images/page_header.jpg");
      return;
    }

    const fetchData = async () => {
      try {
        let imageUrl = "/images/page_header.jpg"; // Default image URL
        let contentComponent = <div>Page Coming Soon...</div>;

        switch (id.toLowerCase()) {
          case 'australia':
            contentComponent = <AustraliaContent />;
            imageUrl = "/global/Australia-hero-img.jpeg";
            break;
          case 'china':
            contentComponent = <ChinaContent />;
            imageUrl = "/global/china-hero-img.jpeg";
            break;
          case 'india':
            contentComponent = <IndiaContent />;
            imageUrl = "/global/india-hero-img.jpeg";
            break;
          case 'kenya':
            contentComponent = <KenyaContent />;
            imageUrl = "/global/Kenya-hero-img.jpeg";
            break;
          case 'malaysia':
            contentComponent = <MalaysiaContent />;
            imageUrl = "/global/Malaysia-hero-img.jpeg";
            break;
          case 'mexico':
            contentComponent = <MexicoContent />;
            imageUrl = "/global/WeFreight-Mexico.jpg";
            break;
          case 'saudi-arabia':
            contentComponent = <SaudiArabiaContent />;
            imageUrl = "/global/Saudi-Arabia-hero-img.jpeg";
            break;
          case 'south-korea':
            contentComponent = <SouthKoreaContent />;
            imageUrl = "/global/South-Korea-hero-img.jpeg";
            break;
          case 'turkey':
            contentComponent = <TurkeyContent />;
            imageUrl = "/global/Turkey-hero-img.jpeg";
            break;
          case 'thailand':
            contentComponent = <ThailandContent />;
            imageUrl = "/global/Thailand-hero-img.jpeg";
            break;
          case 'uae':
            contentComponent = <UAEContent />;
            imageUrl = "/global/UAE-hero-img.jpeg";
            break;
          case 'usa':
            contentComponent = <USAContent />;
            imageUrl = "/global/usa.jpg";
            break;
          case 'vietnam':
            contentComponent = <VietnamContent />;
            imageUrl = "/global/Vietnam-hero-img.jpeg";
            break;
          default:
            contentComponent = <div>Page Coming Soon...</div>;
        }

        setContent(contentComponent);
        setBackgroundImage(imageUrl);
      } catch (error) {
        console.error("Error fetching content:", error);
        setContent(<div>Error loading content</div>);
      }
    };

    fetchData();
  }, [id]);

  // Define country-specific content components
  const AustraliaContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h1>Bespoke logistics services from WeFreight</h1>
          <p className="intro-lead">Australia</p>
          <p className="intro-txt">
            Explore WeFreight’s comprehensive logistics services from our Australia offices, strategically located to cater to diverse business needs nationwide. Our expert team specialises in freight forwarding, customs clearance, warehousing, and distribution, leveraging extensive knowledge of local market dynamics and regulatory requirements.
            Whether you need efficient domestic transport or seamless international shipping, our Australia office offers dependable, cost-effective logistics solutions tailored to your unique needs. Contact us today to see how WeFreight can enhance your business operations in Australia and globally, ensuring smooth supply chain management and timely delivery solutions.
          </p>
        </div>
        <div className="col-6"><CountryForm /></div>
      </div>
    </div>
  );
  
  
  // Define country-specific content components
  const ChinaContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h1>Bespoke logistics services from WeFreight</h1>
          <p className="intro-lead">China</p>
          <p className="intro-txt">
            Explore WeFreight’s comprehensive logistics services from our Australia offices, strategically located to cater to diverse business needs nationwide. Our expert team specialises in freight forwarding, customs clearance, warehousing, and distribution, leveraging extensive knowledge of local market dynamics and regulatory requirements.
            Whether you need efficient domestic transport or seamless international shipping, our Australia office offers dependable, cost-effective logistics solutions tailored to your unique needs. Contact us today to see how WeFreight can enhance your business operations in Australia and globally, ensuring smooth supply chain management and timely delivery solutions.
          </p>
        </div>
        <div className="col-6"><CountryForm /></div>
      </div>
    </div>
  );
  
   // Define country-specific content components
  const IndiaContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h1>Bespoke logistics services from WeFreight</h1>
          <p className="intro-lead">India</p>
          <p className="intro-txt">
            Explore WeFreight’s comprehensive logistics services from our Australia offices, strategically located to cater to diverse business needs nationwide. Our expert team specialises in freight forwarding, customs clearance, warehousing, and distribution, leveraging extensive knowledge of local market dynamics and regulatory requirements.
            Whether you need efficient domestic transport or seamless international shipping, our Australia office offers dependable, cost-effective logistics solutions tailored to your unique needs. Contact us today to see how WeFreight can enhance your business operations in Australia and globally, ensuring smooth supply chain management and timely delivery solutions.
          </p>
        </div>
        <div className="col-6"><CountryForm /></div>
      </div>
    </div>
  );
  
   // Define country-specific content components
  const KenyaContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h1>Bespoke logistics services from WeFreight</h1>
          <p className="intro-lead">Kenya</p>
          <p className="intro-txt">
            Explore WeFreight’s comprehensive logistics services from our Australia offices, strategically located to cater to diverse business needs nationwide. Our expert team specialises in freight forwarding, customs clearance, warehousing, and distribution, leveraging extensive knowledge of local market dynamics and regulatory requirements.
            Whether you need efficient domestic transport or seamless international shipping, our Australia office offers dependable, cost-effective logistics solutions tailored to your unique needs. Contact us today to see how WeFreight can enhance your business operations in Australia and globally, ensuring smooth supply chain management and timely delivery solutions.
          </p>
        </div>
        <div className="col-6"><CountryForm /></div>
      </div>
    </div>
  );
  
  // Define country-specific content components
  const MalaysiaContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h1>Bespoke logistics services from WeFreight</h1>
          <p className="intro-lead">Malaysia</p>
          <p className="intro-txt">
            Explore WeFreight’s comprehensive logistics services from our Australia offices, strategically located to cater to diverse business needs nationwide. Our expert team specialises in freight forwarding, customs clearance, warehousing, and distribution, leveraging extensive knowledge of local market dynamics and regulatory requirements.
            Whether you need efficient domestic transport or seamless international shipping, our Australia office offers dependable, cost-effective logistics solutions tailored to your unique needs. Contact us today to see how WeFreight can enhance your business operations in Australia and globally, ensuring smooth supply chain management and timely delivery solutions.
          </p>
        </div>
        <div className="col-6"><CountryForm /></div>
      </div>
    </div>
  );
  /////////////////////////////////////////////
  
  // Define country-specific content components
  const MexicoContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h1>Bespoke logistics services from WeFreight</h1>
          <p className="intro-lead">Malaysia</p>
          <p className="intro-txt">
            Explore WeFreight’s comprehensive logistics services from our Australia offices, strategically located to cater to diverse business needs nationwide. Our expert team specialises in freight forwarding, customs clearance, warehousing, and distribution, leveraging extensive knowledge of local market dynamics and regulatory requirements.
            Whether you need efficient domestic transport or seamless international shipping, our Australia office offers dependable, cost-effective logistics solutions tailored to your unique needs. Contact us today to see how WeFreight can enhance your business operations in Australia and globally, ensuring smooth supply chain management and timely delivery solutions.
          </p>
        </div>
        <div className="col-6"><CountryForm /></div>
      </div>
    </div>
  );
  
  // Define country-specific content components
  const SaudiArabiaContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h1>Bespoke logistics services from WeFreight</h1>
          <p className="intro-lead">Malaysia</p>
          <p className="intro-txt">
            Explore WeFreight’s comprehensive logistics services from our Australia offices, strategically located to cater to diverse business needs nationwide. Our expert team specialises in freight forwarding, customs clearance, warehousing, and distribution, leveraging extensive knowledge of local market dynamics and regulatory requirements.
            Whether you need efficient domestic transport or seamless international shipping, our Australia office offers dependable, cost-effective logistics solutions tailored to your unique needs. Contact us today to see how WeFreight can enhance your business operations in Australia and globally, ensuring smooth supply chain management and timely delivery solutions.
          </p>
        </div>
        <div className="col-6"><CountryForm /></div>
      </div>
    </div>
  );
  
  // Define country-specific content components
  const SouthKoreaContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h1>Bespoke logistics services from WeFreight</h1>
          <p className="intro-lead">Malaysia</p>
          <p className="intro-txt">
            Explore WeFreight’s comprehensive logistics services from our Australia offices, strategically located to cater to diverse business needs nationwide. Our expert team specialises in freight forwarding, customs clearance, warehousing, and distribution, leveraging extensive knowledge of local market dynamics and regulatory requirements.
            Whether you need efficient domestic transport or seamless international shipping, our Australia office offers dependable, cost-effective logistics solutions tailored to your unique needs. Contact us today to see how WeFreight can enhance your business operations in Australia and globally, ensuring smooth supply chain management and timely delivery solutions.
          </p>
        </div>
        <div className="col-6"><CountryForm /></div>
      </div>
    </div>
  );
  
  // Define country-specific content components
  const TurkeyContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h1>Bespoke logistics services from WeFreight</h1>
          <p className="intro-lead">Malaysia</p>
          <p className="intro-txt">
            Explore WeFreight’s comprehensive logistics services from our Australia offices, strategically located to cater to diverse business needs nationwide. Our expert team specialises in freight forwarding, customs clearance, warehousing, and distribution, leveraging extensive knowledge of local market dynamics and regulatory requirements.
            Whether you need efficient domestic transport or seamless international shipping, our Australia office offers dependable, cost-effective logistics solutions tailored to your unique needs. Contact us today to see how WeFreight can enhance your business operations in Australia and globally, ensuring smooth supply chain management and timely delivery solutions.
          </p>
        </div>
        <div className="col-6"><CountryForm /></div>
      </div>
    </div>
  );
  
  // Define country-specific content components
  const ThailandContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h1>Bespoke logistics services from WeFreight</h1>
          <p className="intro-lead">Malaysia</p>
          <p className="intro-txt">
            Explore WeFreight’s comprehensive logistics services from our Australia offices, strategically located to cater to diverse business needs nationwide. Our expert team specialises in freight forwarding, customs clearance, warehousing, and distribution, leveraging extensive knowledge of local market dynamics and regulatory requirements.
            Whether you need efficient domestic transport or seamless international shipping, our Australia office offers dependable, cost-effective logistics solutions tailored to your unique needs. Contact us today to see how WeFreight can enhance your business operations in Australia and globally, ensuring smooth supply chain management and timely delivery solutions.
          </p>
        </div>
        <div className="col-6"><CountryForm /></div>
      </div>
    </div>
  );
  
  // Define country-specific content components
  const UAEContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h1>Bespoke logistics services from WeFreight</h1>
          <p className="intro-lead">Malaysia</p>
          <p className="intro-txt">
            Explore WeFreight’s comprehensive logistics services from our Australia offices, strategically located to cater to diverse business needs nationwide. Our expert team specialises in freight forwarding, customs clearance, warehousing, and distribution, leveraging extensive knowledge of local market dynamics and regulatory requirements.
            Whether you need efficient domestic transport or seamless international shipping, our Australia office offers dependable, cost-effective logistics solutions tailored to your unique needs. Contact us today to see how WeFreight can enhance your business operations in Australia and globally, ensuring smooth supply chain management and timely delivery solutions.
          </p>
        </div>
        <div className="col-6"><CountryForm /></div>
      </div>
    </div>
  );
  
  // Define country-specific content components
  const USAContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h1>Bespoke logistics services from WeFreight</h1>
          <p className="intro-lead">Malaysia</p>
          <p className="intro-txt">
            Explore WeFreight’s comprehensive logistics services from our Australia offices, strategically located to cater to diverse business needs nationwide. Our expert team specialises in freight forwarding, customs clearance, warehousing, and distribution, leveraging extensive knowledge of local market dynamics and regulatory requirements.
            Whether you need efficient domestic transport or seamless international shipping, our Australia office offers dependable, cost-effective logistics solutions tailored to your unique needs. Contact us today to see how WeFreight can enhance your business operations in Australia and globally, ensuring smooth supply chain management and timely delivery solutions.
          </p>
        </div>
        <div className="col-6"><CountryForm /></div>
      </div>
    </div>
  );
  
  // Define country-specific content components
  const VietnamContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h1>Bespoke logistics services from WeFreight</h1>
          <p className="intro-lead">Malaysia</p>
          <p className="intro-txt">
            Explore WeFreight’s comprehensive logistics services from our Australia offices, strategically located to cater to diverse business needs nationwide. Our expert team specialises in freight forwarding, customs clearance, warehousing, and distribution, leveraging extensive knowledge of local market dynamics and regulatory requirements.
            Whether you need efficient domestic transport or seamless international shipping, our Australia office offers dependable, cost-effective logistics solutions tailored to your unique needs. Contact us today to see how WeFreight can enhance your business operations in Australia and globally, ensuring smooth supply chain management and timely delivery solutions.
          </p>
        </div>
        <div className="col-6"><CountryForm /></div>
      </div>
    </div>
  );


  // Define similar components for other countries

  const countryTitle = id ? id.charAt(0).toUpperCase() + id.slice(1) : "Unknown";

  return (
    <>
      <InnerPageHeading title={`Global Country Estimate - ${countryTitle}`} backgroundImage={backgroundImage} />
      <section className="gfe-sec">
        <div className="container">
          <div className="content">
            {content}
          </div>
        </div>
      </section>
    </>
  );
};

export default CountryDetails;
