function MissionAndVission(){
    return(
        <section className="mission-vission">
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <div className="mav-div">
                            <img className="img-fluid mb20" src="./images/about_img_2.jpg" alt="" height={382} width={555}/>
                            <h3>Our Mission</h3>
                            <p>Our mission is to empower businesses with innovative tracking solutions that enhance operational efficiency and visibility. We strive to deliver reliable, real-time information and robust tools that facilitate superior logistics management and drive growth for our clients.</p>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="mav-div">
                            <img className="img-fluid mb20" src="./images/about_img_3.jpg" alt="" height={382} width={555}/>
                            <h3>Our Vision</h3>
                            <p>Our vision is to redefine the logistics landscape by pioneering innovations in ship tracking and ground shipping. We aspire to create a future where every shipment is seamlessly connected, every operation is optimized, and every customer is empowered with unprecedented visibility and control over their logistics.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MissionAndVission;