import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import InnerPageHeading from "./InnerPageHeading";
import axios from 'axios';


function ContactUs(){
	
	const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    service: '',
    honeypot: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (formData.honeypot) {
      alert('Spam detected. Please try again.');
      return;
    }

    axios.post('https://admissionschoolofbiodesign.in/kite/shiptrack.php', formData, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (response.data.status === 'success') {
        window.location.href = response.data.redirect_url;
      } else {
        alert(response.data.message);
      }
    })
    .catch(error => {
      alert('There was an error submitting the form. Please try again.');
    });
  };
    return(
        <>
		     <Helmet>
				<title>Ship Track - Contact Us</title>
				<meta name="description" content="Fantastic Service" />
				<meta name="keywords" content="Fantastic Service" />
			  </Helmet>
            <InnerPageHeading title="Contact Us"/>
            <section className="contact-sec">
                <div className="container">
                    <p className="lead">We offer a comprehensive range of logistics and commerce services aimed at enhancing operational efficiency and optimizing supply chains. Whether you require support with warehousing, distribution, transportation, or inventory management, our end-to-end solutions can be tailored to meet your unique business requirements. Leveraging our expertise and technology-driven strategies, we guarantee smooth coordination and prompt delivery of goods, allowing you to concentrate on expanding your business. Count on us to manage the intricacies of logistics and commerce, helping you remain competitive in today's rapidly evolving market. Partner with us as your strategic ally to drive success and reach your business objectives.</p>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-label"  htmlFor="name">Name</label>
                                    <input  id="name"
										type="text"
										name="name"
										placeholder="First and Last Name"
										className="form-control"
										value={formData.name}
										onChange={handleChange}
										required/>
                                </div>
                            </div>
                            
                            
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="phone">Phone</label>
                                    <input  id="phone"
										type="tel"
										name="phone"
										placeholder="Phone Number"
										className="form-control"
										value={formData.phone}
										onChange={handleChange}
										required/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="email">Email</label>
                                    <input  id="email"
										type="email"
										name="email"
										placeholder="Email Address"
										className="form-control"
										value={formData.email}
										onChange={handleChange}
										required/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="address">Street Address</label>
                                    <input  id="address"
									type="text"
									name="address"
									placeholder="Street Address"
									className="form-control"
									value={formData.address}
									onChange={handleChange}
									required/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group" htmlFor="city">
                                    <label className="form-label">City</label>
                                    <input  id="city"
									type="text"
									name="city"
									placeholder="City"
									className="form-control"
									value={formData.city}
									onChange={handleChange}
									required/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="service">Select Service</label>
                                    <select id="service"
										  name="service"
										  className="form-control"
										  value={formData.service}
										  onChange={handleChange}
										  required>
                                        <option value="">Select Services</option>
                                        <option value="Ground shipping">Ground shipping</option>
                                        <option value="Air shipping">Air shipping</option>
                                        <option value="Sea delivery">Sea delivery</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Send Now</button>
                    </form>
                </div>
            </section>
        </>
    );
}

export default ContactUs;