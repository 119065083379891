import { Link } from "react-router-dom";
import InnerPageHeading from "./InnerPageHeading";

function ServiceList(){
    return(
        <>
            <InnerPageHeading title="Services"/>
            <section className="hs-sec">
                <div className="container">
                    <h2>Our Service</h2>
                    <div className="row">
                         <div className="col-4">
                            <Link to="/services/ground-shiping" className="service-item">
                                <div className="service-img">
                                    <img src="service/ground_shiping.jpg" alt="Ground shipping" height={203} width={360}/>
                                </div>
                                <div className="service-title">Ground shipping</div>
                            </Link>
                        </div>
                        <div className="col-4">
                            <Link to="/services/air-shipping" className="service-item">
                                <div className="service-img">
                                    <img src="service/air_shipping.jpg" alt="Air shipping" height={203} width={360}/>
                                </div>
                                <div className="service-title">Air shipping</div>
                            </Link>
                        </div>
                        <div className="col-4">
                            <Link to="/services/see-delivery" className="service-item">
                                <div className="service-img">
                                    <img src="service/see_delivery.jpg" alt="Sea delivery" height={203} width={360}/>
                                </div>
                                <div className="service-title">Sea delivery</div>
                            </Link>
                        </div>
                    </div>                   
                </div>
           </section>
        </>
    );
}

export default ServiceList;