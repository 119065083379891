import InnerPageHeading from "./InnerPageHeading";

function PageNotFound(){
    return(
        <>
            <InnerPageHeading title="Page Not Found"/>
            <p>Page Not Found!</p>
        </>
    );
}

export default PageNotFound;