import { Link } from "react-router-dom";
function HomeRecentProject(){
    return(
        <>
            <section className="rp-sec">
                <div className="container">
                    <h2>Our Pricing</h2>                
                    <div className="row">
                        <div className="col-4">
                            <div className="rp-item">
                                <div className="rp-img">
                                    <img src="/service/general.jpg" alt="" height={286} width={360}/>
                                </div>
                                <div className="rp-txt">
                                    <Link to="" className="rp-title">general tariffs</Link>
                                    <p className="rp-location">For details please contact : pramod@shiptrack.co.in</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="rp-item">
                                <div className="rp-img">
                                    <img src="/service/spot.jpg" alt="" height={286} width={360}/>
                                </div>
                                <div className="rp-txt">
                                    <Link to="" className="rp-title">spot options</Link>
                                    <p className="rp-location">For details please contact : pramod@shiptrack.co.in</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="rp-item">
                                <div className="rp-img">
                                    <img src="/service/contract.jpg" alt="" height={286} width={360}/>
                                </div>
                                <div className="rp-txt">
                                    <Link to="" className="rp-title">contractual rates</Link>
                                    <p className="rp-location">For details prease contact : nilam@shiptrack.co.in</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
           </section>
        </>
    );
}

export default HomeRecentProject;