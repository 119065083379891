import InnerPageHeading from "./InnerPageHeading";

function TermCondition(){
    return(
        <>
            <InnerPageHeading title="Term and Condition"/>
            <section className="gfe-sec">
                <div className="container">
                    <div className="cooming-soon">
                        Page Comming Soon...
                    </div>
                </div>
           </section>
        </>
    );
}

export default TermCondition;