import { Link } from "react-router-dom";

function InnerPageHeading(props){
	const { title, backgroundImage } = props;

    // Style object for dynamic background image
    const headerStyle = {
        backgroundImage: `url(${backgroundImage || '/images/page_header.jpg'})`,        
    };
    return(
        <>
            <section className="page-header" style={headerStyle}>
                <div className="container">
                    <div className="pahe-header-inner">
                        <h1>{props.title}</h1>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>{props.title}</li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default InnerPageHeading;
