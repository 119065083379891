import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import InnerPageHeading from "./InnerPageHeading";
import HomeWhyChooseUse from "./HomeWhyChooseUs";
import MissionAndVission from "./MissionAndVission";

function AboutUs(){
    return(
        <>
			<Helmet>
				<title>Ship Track - About Us</title>
				<meta name="description" content="Fantastic Service" />
				<meta name="keywords" content="Fantastic Service" />
			  </Helmet>
           <InnerPageHeading title="About Us"/>
           <section className="home-intro">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <h1>Fantastic Service</h1>
                            <p className="intro-lead">At APN ShipTrack International EXIM LLP, we take great pride in giving our valued clients exceptional service. Our reputation as a leading provider of logistics and commerce solutions is thanks to our unwavering commitment to excellence as the director. </p>
                            <p className="intro-txt">Our team of experts is committed to delivering personalized and customized services that meet the unique needs of each client. Our goal is to ensure that every shipment is handled with the highest level of care and precision. By utilizing our state-of-the-art technology platform, we can track shipments in real-time, giving our clients peace of mind and transparency throughout the entire process. Our dedication to continuous improvement and innovation stems from our commitment to investing in the latest technology and training our team to provide the highest level of service. The trust our clients place in us means a lot to us, and we will keep striving for excellence in everything we do.</p>
                          
                        </div>
                        <div className="col-6">
                            <img src="images/about_img_1.jpg" alt="" height={382} width={555}/>
                        </div>
                    </div>
					
					<div className="row">
						<div className="col-6">
						  <div className="mb30">
							<h2 className="mb20">You can rely on it</h2>
							<p className="intro-txt">APN ShipTrack International EXIM LLP. is a shining example of excellence in logistics and commerce, offering a comprehensive suite of services to facilitate seamless global trade. We have a team of dedicated professionals with extensive industry knowledge and unwavering commitment that ensure that your shipments traverse the complexities of international trade with precision and efficiency. We offer tailored solutions that meet the specific needs of our clients, from freight forwarding and customs clearance to warehousing and distribution. Our extensive network of trusted partners and strategic alliances across the globe allows us to offer cost-effective and reliable transportation options, ensuring your cargo reaches its destination on time and within budget. APN ShipTrack International EXIM LLP. provides you with access to expert knowledge and personalized support, which will enable your business to thrive in the global marketplace.</p>			
							
						  </div>
						</div>
						<div className="col-6">
						  <img src="/images/about1.jpeg" alt="" className="img-fluid" />
						</div>
					  </div>
					  
					   <div className="row">
						<div className="col-6">
						  <div className="mb30">
							<h2 className="mb20">Easy. Fast. Cheap.</h2>
							<p className="intro-txt">APN ShipTrack International EXIM LLP is the perfect partner for seamless logistics and commerce solutions, as it provides an unmatched combination of ease, speed, and affordability. With our comprehensive services, we can meet the diverse needs of businesses and individuals, guaranteeing a hassle-free and cost-effective experience. APN ShipTrack allows you to enjoy:</p><p className="intro-txt">Unmatched ease: Our user-friendly platform and dedicated team of experts simplify every aspect of logistics and commerce. The entire process of booking shipping, tracking orders, and managing customs clearance is simplified by us to save you time and effort.</p><p className="intro-txt">Exceptional speed: We understand the importance of timely deliveries. With our optimized supply chain and strategic partnerships, we can ensure that your shipments are delivered quickly and reliably, meeting even the most urgent deadlines.</p><p className="intro-txt">Unrivalled affordability: At APN ShipTrack, we believe in providing cost-effective solutions without compromising quality. The best value for your money is assured by our competitive rates and transparent pricing structure. Optimizing your logistics and commerce operations while maximizing your profitability is possible through partnering with us</p>
							<Link to="/contact" className="btn-default-link">
							  Contact us
							</Link>
							
						  </div>
						</div>
						<div className="col-6">
						  <img src="/images/about2.jpeg" alt="" className="img-fluid" />
						</div>
					  </div>
					
                </div>
           </section>
           <HomeWhyChooseUse/>
           <MissionAndVission/>
        </>
    );
}

export default AboutUs;