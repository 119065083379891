function HomeWhyChooseUse(){
    return(
        <>
             <section className="why-chooseus-sec">
                <div className="container">
                    <h2>Why choose us toproofer</h2>                
                    <div className="row">
                        <div className="col-4">
                            <div className="why-choose-us">
                                <div className="icon">
                                    <i className="fa fa-star-o"></i>
                                </div>
                                <h3>Timely Delivery</h3>
                                <p>Timely delivery is of the utmost importance to APN ShipTrack International EXIM LLP. </p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="why-choose-us">
                                <div className="icon">
                                    <i className="fa fa-user-o"></i>
                                </div>
                                <h3>Global delivery</h3>
                                <p>APN ShipTrack International EXIM LLP is a well-known provider of global delivery and commerce solutions. </p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="why-choose-us">
                                <div className="icon">
                                    <i className="fa fa-cog"></i>
                                </div>
                                <h3>24/7  Support</h3>
                                <p>APN ShipTrack International EXIM LLP provides comprehensive 24/7 support to its valued customers. </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
           </section>
        </>
    );
}

export default HomeWhyChooseUse;