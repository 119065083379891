import { Link } from "react-router-dom";
import InnerPageHeading from "./InnerPageHeading";
function GetFreeStimate(){
    return(
        <>
            <InnerPageHeading title="Get Free Estimate"/>
            <section className="hs-sec">
                <div className="container">
                    <h2>Global Countries</h2>
                    <div className="row">
                         <div className="col-4">
                            <Link to="/global/australia" className="service-item">
                                <div className="service-img">
                                    <img src="global/Australia-hero-img.jpeg" alt="Australia" height={203} width={360}/>
                                </div>
                                <div className="service-title">Australia</div>
                            </Link>
                        </div>
                        <div className="col-4">
                            <Link to="/global/china" className="service-item">
                                <div className="service-img">
                                    <img src="global/china-hero-img.jpeg" alt="China" height={203} width={360}/>
                                </div>
                                <div className="service-title">China</div>
                            </Link>
                        </div>
                        <div className="col-4">
                            <Link to="/global/india" className="service-item">
                                <div className="service-img">
                                    <img src="global/india-hero-img.jpeg" alt="India" height={203} width={360}/>
                                </div>
                                <div className="service-title">India</div>
                            </Link>
                        </div>
						
						
						<div className="col-4">
                            <Link to="/global/indonesia" className="service-item">
                                <div className="service-img">
                                    <img src="global/Indonesia-hero-img.jpeg" alt="Indonesia" height={203} width={360}/>
                                </div>
                                <div className="service-title">Indonesia</div>
                            </Link>
                        </div>
						<div className="col-4">
                            <Link to="/global/kenya" className="service-item">
                                <div className="service-img">
                                    <img src="global/Kenya-hero-img.jpeg" alt="Kenya" height={203} width={360}/>
                                </div>
                                <div className="service-title">Kenya</div>
                            </Link>
                        </div>
						<div className="col-4">
                            <Link to="/global/malaysia" className="service-item">
                                <div className="service-img">
                                    <img src="global/Malaysia-hero-img.jpeg" alt="Malaysia" height={203} width={360}/>
                                </div>
                                <div className="service-title">Malaysia</div>
                            </Link>
                        </div>
						<div className="col-4">
                            <Link to="/global/mexico" className="service-item">
                                <div className="service-img">
                                    <img src="global/WeFreight-Mexico.jpg" alt="Mexico" height={203} width={360}/>
                                </div>
                                <div className="service-title">Mexico</div>
                            </Link>
                        </div>
						<div className="col-4">
                            <Link to="/global/saudi-arabia" className="service-item">
                                <div className="service-img">
                                    <img src="global/Saudi-Arabia-hero-img.jpeg" alt="Saudi Arabia" height={203} width={360}/>
                                </div>
                                <div className="service-title">Saudi Arabia</div>
                            </Link>
                        </div>
						<div className="col-4">
                            <Link to="/global/south-korea" className="service-item">
                                <div className="service-img">
                                    <img src="global/South-Korea-hero-img.jpeg" alt="South Korea" height={203} width={360}/>
                                </div>
                                <div className="service-title">South Korea</div>
                            </Link>
                        </div>
						<div className="col-4">
                            <Link to="/global/turkey" className="service-item">
                                <div className="service-img">
                                    <img src="global/Turkey-hero-img.jpeg" alt="Türkiye" height={203} width={360}/>
                                </div>
                                <div className="service-title">Türkiye</div>
                            </Link>
                        </div>
						<div className="col-4">
                            <Link to="/global/thailand" className="service-item">
                                <div className="service-img">
                                    <img src="global/Thailand-hero-img.jpeg" alt="Thailand" height={203} width={360}/>
                                </div>
                                <div className="service-title">Thailand</div>
                            </Link>
                        </div>
						<div className="col-4">
                            <Link to="/global/uae" className="service-item">
                                <div className="service-img">
                                    <img src="global/UAE-hero-img.jpeg" alt="UAE" height={203} width={360}/>
                                </div>
                                <div className="service-title">UAE</div>
                            </Link>
                        </div>
						<div className="col-4">
                            <Link to="/global/usa" className="service-item">
                                <div className="service-img">
                                    <img src="global/usa.jpg" alt="USA" height={203} width={360}/>
                                </div>
                                <div className="service-title">USA</div>
                            </Link>
                        </div>
						<div className="col-4">
                            <Link to="/global/vietnam" className="service-item">
                                <div className="service-img">
                                    <img src="global/Vietnam-hero-img.jpeg" alt="Vietnam" height={203} width={360}/>
                                </div>
                                <div className="service-title">Vietnam</div>
                            </Link>
                        </div>
						
						
                    </div>                   
                </div>
           </section>
        </>
    );
}

export default GetFreeStimate;